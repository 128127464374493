export enum Section {
  Signature = "Signature",
  Hero = "Hero",
  About = "About",
  Gallery = "Gallery",
  Venue = "Venue",
  Hotels = "Hotels",
  Faq = "Faq",
  Rsvp = "Rsvp",
}
